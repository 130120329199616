<style scoped lang="scss">
  .curriculum {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .look-btn {
    width: 60px;
    padding: 0;
    height: 30px;
    line-height: 30px;
    color: #6340c8;
    background-color: #f2edff;
    border-radius: 6px;
    border: solid 1px #c0adf9;

    &:hover {
      color: #ffffff;
      background-color: #6340c8;
      border: 1px solid #6340c8;

      &:before,
      &:after {
        background: #6340c8;
      }
    }
  }

  .curriculum-search {
    flex: none;
  }

  // 分页
  .paging {
    margin-top: 10px;
    // padding-bottom: 50px;
    text-align: right;
  }

  // 新增课程
  .new-courses {
    width: 100%;
    height: 68px;
    line-height: 68px;
    background-color: #f6f7fc;
    font-size: 16px;
    font-weight: 600;
    color: #1b1b1b;
    position: relative;
    text-indent: 36px;
  }

  .newcourses {
    .new-courses-content {
      background: #ffffff;
      padding: 50px 90px 43px;
      box-sizing: border-box;
    }
  }

  .new-courses::after {
    content: "";
    width: 5px;
    height: 18px;
    background-color: #6340c8;
    border-radius: 2.5px;
    position: absolute;
    top: 26px;
    left: 25px;
  }

  ::v-deep {
    .newcourses .el-dialog {
      border-radius: 10px;
      overflow: hidden;
    }

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 0;
    }

    .curriculum-search .el-input--medium .el-input__inner {
      min-width: 250px;
      background: #f1f2f8;
      border-radius: 10px;
      border: none;
    }

    // .curriculum-search .el-form-item--medium .el-form-item__label {
    //   line-height: 50px;
    // }
    .curriculum-search .el-form--inline .el-form-item {
      margin-right: 19px;
    }



  }

  // 树状结构
  .flow-tree {
    background-color: #f6f7fc;
    border-radius: 10px;
    box-sizing: border-box;
    height: 100%;
    padding: 10px 20px 10px 0;
    color: #1a1a1b;
  }

  ::v-deep {
    .el-checkbox__inner {
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px
    }

    .el-checkbox__inner::after {
      left: 9px;
      top: 5px;
    }

    .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
      top: 11px;
    }

    el-tree-node {
      .el-tree-node__children {
        //   overflow: visible !important;
      }
    }

    .el-tree-node__children .el-tree-node__label {
      padding-left: 42px !important;
    }

    .el-tree-node__expand-icon {
      position: absolute;
      right: 5%;
    }

    .el-tree-node__label {
      padding-left: 20px;
    }

    .el-tree-node:focus>.el-tree-node__content {
      background: #6340c8;
      color: #ffffff !important;
      margin-right: 20px;
      border-radius: 0 10px 10px 0;
    }

    .el-tree-node__content {
      height: 42px;
      padding-left: 20px;
    }

    .el-icon-caret-right:before {
      content: "\e641" !important;
      font-family: "iconfont";
      font-size: 16px;
    }
  }

  // 新增课程
  ::v-deep {
    .new-courses-content .el-input--medium .el-input__inner {
      height: 46px;
      line-height: 46px;
      width: 393px;
      background: #f6f7fa;
      border: none;
      border-radius: 10px;
    }

    .new-courses-content .el-form-item--medium .el-form-item__label {
      line-height: 46px;
    }

    .el-dialog .el-dialog__body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  // 师资卡片
  .teacher-card {
    height: 6.25vw;
    margin: 6px 0 26px;
    display: flex;

    .card-list {
      width: 11.25vw;
      margin-right: 40px;
      height: 6.25vw;
      border-radius: 10px;
      display: flex;
      position: relative;

      .card-list-left {
        flex: 1;
        color: #ffffff;
        font-size: 0.73vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1.25vw 1.35vw;

        .number {
          font-size: 1.56vw;
          font-weight: 600;
        }

        .title {
          margin-top: 0.6vw;
        }
      }

      .wave {
        position: absolute;
        left: -7vw;
        bottom: -8.8vw;
        width: 11.25vw;

        img {
          width: 200%;
        }
      }

      .card-list-right {
        width: 4.74vw;

        img {
          width: 4.74vw;
          height: 5.05vw;
          margin-top: 0.6vw;
        }
      }
    }

    .card-list:nth-child(1) {
      background: #f9b531;
    }

    .card-list:nth-child(2) {
      background: #f7657c;
    }

    .card-list:nth-child(3) {
      background: #472697;
    }
  }

  .curriculum-table {
    flex: 1;
    overflow: hidden;
    // display: flex;

    // .grade-class {
    //   width: 200px;
    //   display: inline;
    //   flex: none;
    //   background-color: #f6f7fc;
    //   border-radius: 10px;
    //   overflow-y: scroll;
    // }

    .table_center {
      height: calc(100%);
      overflow-y: scroll;
    }
  }

  // 状态
  .normal {
    font-size: 14px;
    color: #40c84a;
  }

  .unusual {
    font-size: 14px;
    color: #e53f32;
  }
</style>
<template>
  <section class="curriculum">
    <div class="curriculum-search">
      <el-form :inline="true" :model="searchform" class="demo-form-inline">
        <el-form-item label="">
          <el-select filterable remote :remote-method="getSchoolList" :loading="loading" v-model="searchform.scsch_id"
                     placeholder="请选择学校" clearable="true">
            <el-option v-for="item in school_list" :key="item.value" :label="item.key" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-cascader placeholder="请选择班级" v-model="searchform.sccla_grades" :options="data2"
                       :props="{ value: 'key', label: 'label' }" clearable collapse-tags />
        </el-form-item>
        <el-form-item label="">
          <el-input v-model="searchform.query_word" placeholder="输入关键字"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="init('search')" class="lightpurple-btn">搜索</el-button>
          <el-button type="primary" @click="handelexport()" class="lightyellow-btn" v-loading="btnLoad">导出</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="teacher-card">
      <div class="card-list">
        <div class="card-list-left">
          <p>
            <countTo class="number" :startVal="0" :endVal="newcoursesData.stu_count" :duration="3000"></countTo>位
          </p>
          <p class="title">学生总数</p>
        </div>
        <div class="card-list-right">
          <img src="../../assets/book.png" alt="" />
        </div>
        <div class="wave">
          <img src="../../assets/wave.png" alt="" />
        </div>
      </div>
      <div class="card-list">
        <div class="card-list-left">
          <p>
            <countTo class="number" :startVal="0" :endVal="newcoursesData.sure_wacom" :duration="3000"></countTo>个
          </p>
          <p class="title">手写板数量</p>
        </div>
        <div class="card-list-right">
          <img src="../../assets/book.png" alt="" />
        </div>
        <div class="wave">
          <img src="../../assets/wave.png" alt="" />
        </div>
      </div>
      <div class="card-list">
        <div class="card-list-left">
          <p>
            <countTo class="number" :startVal="0" :endVal="newcoursesData.coverage_rate" :duration="3000"></countTo>%
          </p>
          <p class="title">手写板覆盖率</p>
        </div>
        <div class="card-list-right">
          <img src="../../assets/book.png" alt="" />
        </div>
        <div class="wave">
          <img src="../../assets/wave.png" alt="" />
        </div>
      </div>
    </div>

    <div class="curriculum-table">
      <!-- <div class="grade-class">
        <el-tree class="flow-tree" :data="data2" :props="defaultProps" accordion :filter-node-method="filterNode"
          ref="tree2">
        </el-tree>
      </div> -->
      <div class="table_center">
        <el-table :data="tableData" style="width: 100%" :header-cell-style="{
          background: '#E8EAF3',
          color: '#1a1a1b',
          'text-align': 'center',
          fontWeight: 500,
        }">
          <!-- <el-table-column type="selection" class="checkbox-inp" width="55"></el-table-column> -->

          <el-table-column prop="stuser_id" label="ID"> </el-table-column>
          <el-table-column prop="stuser_name" label="姓名"> </el-table-column>
          <!-- <el-table-column prop="province" label="省份"> </el-table-column> -->
          <el-table-column prop="stuser_province_city_area" label="省/市/区"> </el-table-column>
          <el-table-column prop="type" label="学校类型">
            <template slot-scope="scope">
              <p>{{ scope.row.stuser_schtype == 10 ? "小学" : scope.row.stuser_schtype == 20 ? "初中" :
                scope.row.stuser_schtype == 30 ?
                "高中" :scope.row.stuser_schtype == 40 ? "职高":'未知' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="stuser_stuno" label="学号"> </el-table-column>
          <el-table-column prop="scsch_name" label="学校名称"> </el-table-column>
          <el-table-column prop="sccla_name" label="班级"> </el-table-column>
          <el-table-column prop="stboaTitle" label="手写板信息">
            <template slot-scope="scope">
              <p>{{ scope.row.stboaTitle == ''?'未购买':scope.row.stboaTitle  }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="添加时间"> </el-table-column>
          <el-table-column label="状态" width="100">
            <template slot-scope="scope">
              <span :class="{ normal: scope.row.stuser_accstatus == 10, unusual: scope.row.stuser_accstatus == 20 }">{{
                scope.row.stuser_accstatus == 10 ? "启用" : "禁用" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <!-- <el-button class="look-btn" @click="handleClick(scope.row)">详情</el-button> -->
              <el-button class="look-btn"  @click="$router.push({ name: 'STUDENT_INFO', params: { id: scope.row.stuser_id } })">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="paging">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10"
                     next-text="下一页" layout=" pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </section>
</template>

<script>
    import { getSchSchoolKeyVal, getSchClassTreeList } from "@api/SchSchool";
    import { stuuserList,exportData } from "@api/student";

    import countTo from "vue-count-to";
    export default {
        name: "",
        components: { countTo },
        data() {
            return {
                currentPage: 1,
                total: 0,
                searchform: {
                    sccla_grades:[],
                    pageindex: 1,
                    pagesize: 10,
                },
                btnLoad:false,
                school_list: [],
                loading: false,
                // 表格数据
                tableData: [],
                newcoursesData: {
                },

                data2: [],
                defaultProps: {
                    children: "children",
                    label: "label",
                },
            };
        },

        created() {
            this.init();
            this.getClassSelete()
        },
        methods: {
            onSubmit() { },
            handleClick(row) {
                console.log(row);

                this.$router.push({ path: "/student/info",params: {id:row.stuser_id}});
            },

            // 修改当前
            handleCurrentChange() {
                this.searchform.pageindex = this.currentPage;
                this.init()
            },
            /**初始化 */
            async init(val) {
                if (val == "search") {
                    this.searchform.pageindex = 1;
                    if( this.searchform.sccla_grades.length>0){
                        this.searchform.sccla_id = this.searchform.sccla_grades[1];
                    }
                    // delete this.searchform.sccla_grades;
                }

                const res = await stuuserList(this.searchform);
                if (res.status == 200) {
                    this.tableData = res.data.data;
                    this.total = res.data.allcount;
                    this.newcoursesData = res.data.wacom_data;
                }

                this.getSchoolList();
            },
            /*导出 */
            async handelexport(){
                this.btnLoad = true;
                let { data } = await exportData(this.searchform);
                console.log(data);
                this.btnLoad = false;
                if (data.full_file_url) {
                    this.$fileDownload(data.full_file_url)
                };
            },
            /**获取学校下拉 */
            async getSchoolList(query) {
                this.loading = true;
                const res = await getSchSchoolKeyVal("scsch_name", "scsch_id", "", query);

                if (res.status == 200) {
                    this.school_list = res.data;
                    this.loading = false;
                }
            },
            /**获取班级下拉 */
            async getClassSelete() {
                const res = await getSchClassTreeList();
                if (res.status == 200) {
                    this.data2 = res.data
                }
            },
            /**转跳详情 */
            handelinfo(row) {
                this.$router.push({ name: 'TEACHER_INFO', params: { id: row.teuser_id } });
            },
            // 树状
            filterNode(value, data) {
                if (!value) return true;
                return data.label.indexOf(value) !== -1;
            },
        },
    };
</script>
